<template>
  <v-container fluid>
    <main-layout />
    <continue-register v-if="notRegistered"></continue-register>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <div class="mx-4">
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h2 class="ml-2 mb-4 mt-2 font-weight-bold">
              {{ $t("field.user_data") }}
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :label="$t('field.email')"
              color="primary"
              :rules="emailRules"
              validate-on-blur
              required
              outlined
              v-model="user.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :hint="$t('validation.password')"
              :label="$t('field.password')"
              outlined
              hide-details="auto"
              v-model="user.password"
            >
              <v-icon slot="append" color="primary" @click="show1 = !show1">{{
                show1 ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-5" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h2 class="ml-2 mt-2 font-weight-bold">
              {{ $t("group_fields.group_data") }}
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("group_fields.group_name") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacyGroup.name"
              :label="$t('group_fields.group_name')"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.representative_name") }}</v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('field.representative_name')"
              v-model="pharmacyGroup.representative"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="required"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.cif") }}</v-subheader
            >
            <v-text-field
              :label="$t('field.cif_format')"
              v-model="pharmacyGroup.cif"
              :rules="cif"
              validate-on-blur
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.phone_number") }}</v-subheader
            >
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacyGroup.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateFormValid="updateFormValid"
              @updateCountry="updateCountry1"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.fiscal_name") }}</v-subheader
            >
            <v-text-field
              name="input-10-1"
              v-model="pharmacyGroup.fiscal_name"
              :label="$t('field.fiscal_name')"
              hide-details="auto"
              validate-on-blur
              :rules="required"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.fiscal_address") }}</v-subheader
            >
            <vuetify-google-autocomplete
              ref="address"
              :id="'map3'"
              placeholder=""
              classname="form-control"
              :rules="address_validator"
              validate-on-blur
              :label="$t('field.address_placeholder')"
              outlined
              :hide-details="false"
              v-on:placechanged="getBillingAddressData"
              country="es"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1 pl-2">{{
              $t("field.pharmacy_group_logo")
            }}</span>
            <v-row class="pa-0 ma-0" justify-sm="center">
              <v-col cols="7" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  outlined
                  large
                  rounded
                  @click="addlogo"
                  style="text-transform: none"
                >
                  {{ $t("register.pharmacy_logo") }}
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-file-input
              small-chips
              id="logo"
              :label="$t('field.logo')"
              truncate-length="15"
              v-model="pharmacyGroup.logo"
              class="pa-0"
              :class="pharmacyGroup.logo != null ? '' : 'd-none'"
              accept="image/*"
            ></v-file-input>
          </v-col>
        </v-row>
      </div>
      <v-row class="mx-4 my-6" justify="center">
        <v-col cols="10" sm="3" md="2" class="pa-0 mt-2 mr-0 text-center">
          <div>
            <v-btn
              color="secondary"
              style="text-capitalize text-h6 font-weight-light"
              block
              large
              :disabled="!formValid || !phoneValid"
              rounded
              @click.prevent="onLogin"
            >
              {{ $t("register.pharmacy_group") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import MainLayout from "@/components/layouts/Main.vue";
import VueTelInput from "@/components/shared/VueTelInput.vue";
//import _ from "lodash";
export default {
  components: {
    MainLayout,
    VueTelInput,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      formValid: true,
      phoneValid: true,
      countryPharmacyTelephone: null,
      pharmacyGroup: {
        user: null,
        logo: null,
        name: null,
        fiscal_name: null,
        representative: null,
        cif: null,
        email: null,
        phone_number: null,
        billing_address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
      },
      requiredDefault: true,
      notRegistered: false,
      rememberme: false,
      show1: false,
      userTypes: ["pharmacy", "candidate", "expert", "alquimia"],
      emailRules: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) => /.+@.+/.test(v) || this.$i18n.t("validation.email_format"),
      ],
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      cif: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$)|(^\d{8}[a-zA-Z]{1}$)/.test(v) ||
          this.$i18n.t("validation.cif"),
      ],

      address_validator: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacyGroup.billing_address.latitude &&
            this.pharmacyGroup.billing_address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
    };
  },
  computed: {
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ userInfo: "user/info" }),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? true : false;
    },
  },
  methods: {
    ...mapActions({ login: "user/login" }),
    ...mapActions({ logout: "user/logout" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ generateUser: "user/generateUser" }),
    /* ..mapActions({ generateUser: "pharmacy/createGroup" }), */
    ...mapActions({ createPharmacyGroup: "pharmacy/createPharmacyGroup" }),
    ...mapActions({ login: "user/login" }),
    ...mapActions({ getUser: "user/getUser" }),
    async onLogin() {
      if (this.$refs.form.validate()) {
        try {
          var [status, user_id] = await this.generateUser({
            email: this.user.email,
            password: this.user.password,
          });
        } catch (e) {
          this.$notify({
            title: this.$i18n.t("notification.error"),
            text: this.$i18n.t("notification.email_taken"),
            type: "error",
          });
        }
        if (status === 201 || status === 200) {
          await this.login(this.user);
          try {
            await this.formatPharmacyGroupTelephone();
            this.pharmacyGroup.user = user_id;
            this.pharmacyGroup.email = this.user.email;
            let status = await this.createPharmacyGroup(this.pharmacyGroup);
            if (status === 201) {
              this.getUser().then(() => {
                this.$acl.change(this.userType);
                if (this.userType === "pharmacygroup")
                  this.$router.push({ name: "offers" });
                else {
                  this.$router.push({ name: "login" });
                }
              });
            }
          } catch (e) {
            Object.values(e.response.data).forEach((elm) => {
              this.$notify({
                title: this.$i18n.t("notification.error"),
                text: Array.isArray(elm) ? elm.join(", ") : elm,
                type: "error",
              });
            });
          }
        }
      }
    },
    updateFormValid(valid) {
      this.phoneValid = valid;
    },
    updatePhoneNumber(phone) {
      this.pharmacyGroup.phone_number = phone;
    },
    updateCountry1(country) {
      this.countryPharmacyTelephone = "+" + country;
    },
    addlogo() {
      document.getElementById("logo").click();
    },
    getBillingAddressData(addressData, placeResultData) {
      this.pharmacyGroup.billing_address = {
        raw: addressData.name,
        route: addressData.route,
        street_number: addressData.street_number,
        formatted: placeResultData.formatted_address,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        locality: {
          postal_code: addressData.postal_code,
          name: addressData.locality,
          state: {
            name: placeResultData.address_components.find(
              (elm) => elm.short_name == addressData.administrative_area_level_2
            ).long_name,
            code:
              placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name +
              "-" +
              addressData.administrative_area_level_2,
            country: {
              name: addressData.country,
              code: placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name,
            },
          },
        },
      };
    },
    formatPharmacyGroupTelephone() {
      let phone = this.pharmacyGroup.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacyGroup.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacyGroup.phone_number = country + " " + phone2;
      } else {
        let tel =
          this.countryPharmacyTelephone + this.pharmacyGroup.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacyGroup.phone_number = country + " " + phone2;
      }
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined fieldset {
  border-color: #718f94 !important;
}
.v-btn border {
  border-color: #718f94;
}
</style>
