<template>
  <div class="m-0 p-0">
    <vue-tel-input-vuetify
      class="p-0 m-0"
      full-width
      outlined
      v-model="internallyPhone"
      :onlyCountries="['es', 'fr', 'pt', 'gb']"
      select-label="Code"
      defaultCountry="es"
      @input="onInput"
      @blur="onBlur"
      :label="$t('field.phone_number')"
      :error-messages="errorMessage"
      :placeholder="$t('field.phone_number')"
    >
      ></vue-tel-input-vuetify
    >
    <div v-if="error" class="v-text-field__details wrap-error m-0">
      <div class="v-messages theme--light error--text wrap-error" role="alert">
        <div class="v-messages__wrapper wrap-error">
          <div class="v-messages__message wrap-error">{{ errorMessage }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      blur: true,
      errorMessage: "",
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
      errorElement: "",
    };
  },
  props: {
    phoneNumber: String,
    requiredPass: Boolean,
  },
  computed: {
    internallyPhone: {
      get() {
        return this.phoneNumber;
      },
      set(value) {
        this.internallyPhoneSend(value);
      },
    },
  },
  mounted() {
    this.error = false;
    this.deleteDetails();
    this.formatMargin();
    this.deleteIcon();
    this.requiredPass = true;
  },
  methods: {
    internallyPhoneSend(value) {
      this.$emit("updatePhoneNumber", value);
    },
    onInput(formattedNumber, { number, valid, country }) {
      this.phone.valid = valid;
      this.phone.country = country;
      this.phone.number = number;
      this.$emit("updateCountry", country.dialCode);
      // this.$emit("updateFormValid", false);
      // this.errorMessage = this.$i18n.t("validation.phone_number");
      // if (this.blur == true) {
      //   this.onBlur();
      //   this.blur = false;
      // }
    },
    onBlur() {
      if (this.phone.valid) {
        this.error = false;
        this.$emit("updateFormValid", true);
        this.errorMessage = "";
      } else {
        this.error = true;
        this.$emit("updateFormValid", false);

        this.errorMessage = this.$i18n.t("validation.phone_number");
        if (this.phone.number.input == "") {
          this.errorMessage = this.$i18n.t("validation.required");
          if (this.requiredPass == false) {
            this.error = false;
            this.errorMessage = "";
            this.$emit("updateFormValid", true);
          }
        }
      }
    },
    deleteDetails() {
      const details = document.querySelectorAll(
        ".vue-tel-input-vuetify .v-text-field__details"
      );
      for (let i = 0; i < details.length; i++) {
        details[i].parentNode.removeChild(details[i]);
      }
    },
    deleteIcon() {
      const countryIcon = document.querySelectorAll(
        ".vue-tel-input-vuetify .v-input__icon"
      );
      for (let i = 0; i < countryIcon.length; i++) {
        countryIcon[i].parentNode.removeChild(countryIcon[i]);
      }
    },
    formatMargin() {
      const marginElementInput = document.querySelectorAll(
        ".vue-tel-input-vuetify .v-text-field--outlined .v-input__control .v-input__slot"
      );
      const marginElementCountry = document.querySelectorAll(
        ".vue-tel-input-vuetify .country-code  .v-input__control .v-input__slot"
      );

      for (let i = 0; i < marginElementInput.length; i++) {
        marginElementInput[i].style.margin = "0px!important";
        marginElementInput[i].classList.add("format-margin");
      }

      for (let i = 0; i < marginElementCountry.length; i++) {
        marginElementCountry[i].style.margin = "0px!important";
        marginElementCountry[i].classList.add("format-margin");
      }
    },
  },
};
</script>
<style scoped>
.wrap-error {
  padding-left: 3px !important;
  margin: 0 !important;
}

.format-margin {
  margin: 0 !important;
}
</style>
